.edit-page-root-container {

    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    background-color: var(--content-page-bg-color);


    .page-content {


        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        min-width: 800px;
        margin-top: 10px;


        .page-header {

            height: 56px;
            position: relative;
            padding: 5px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            background-color: var(--content-page-section-bg-color);
            border-radius: 5px;

            .nav-button {
                cursor: pointer;
            }

            .title {
                font-size: 20px;
                font-weight: 500;
            }

            .action-btn-container {
                margin: auto;
                margin-right: 10px;
                display: flex;
                gap: 10px;

                .action-btn {

                    &.btn-discard {
                        background-color: red;
                        color: white;
                    }

                    &.btn-save {
                        background-color: green;
                        color: white;
                    }
                }
            }
        }

        .page-details {
            margin-top: 20px;
            overflow: auto;
            border-radius: 5px;
            

            .detail-section{
                background-color: var(--content-page-section-bg-color);
                padding: 30px 30px;
                margin-top:10px;
                border-radius: 5px;
            }
        }
    }
}