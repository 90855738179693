
@media (max-width: 1100px) {

    .summary-page-root-container {
        transition: width .1s;
        overflow: hidden;
        min-width: 50px;
        max-width: 320px;
        height: 100%;
        position: absolute;
        right: 0px;
        top: 0;
        background: white;
        z-index: 9999;
        box-shadow: #00000059 0 5px 15px;
    }

}

.summary-page-root-container {
    transition: width .5s;
    overflow: hidden;
    min-width: var(--right-summary-panel-min-width);
    max-width: var(--right-summary-panel-max-width);
    height: 100%;
    background: var(--right-summary-panel-bg-color);
    // border-left: 1px solid #00000059; //var(--top-toolbar-bg-color);
    box-shadow: #00000059 0 5px 15px;

    .close-btn {
        position: absolute;

        margin: auto;
        // top: 10px;
        right: 5px;
        cursor: pointer;
    }

    .open-btn {
        position: absolute;
        // top: 10px;
        right: 5px;
        cursor: pointer;
    }


    .page-container {
        height: 100%;
        width: 320px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        // background: white;

        .page-header {
            display: flex;
            padding: 0px 10px;
            height: 48px;
            align-items: center;

            .title {
                // margin-top: 10px;
                font-weight: bold;
                font-size: 20px;
                color: midnightblue;
                text-transform: uppercase;
                text-wrap: nowrap;
                flex: 1;
            }

        }

        .page-content {
            display: flex;
            flex-direction: column;
            // gap: 15px;
            flex: 1;

            .section {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid #d3d3d385;

                .section-title {
                    font-size: 16px;
                    font-weight: 600;
                    color: #1A67A3;
                    padding: 5px;
                }

                .section-content {
                    display: flex;
                    gap: 10px;
                    padding: 10px;

                }

                .section-footer {}
            }

        }

        .page-footer {
            display: flex;
            /* padding: 10px; */
            gap: 10px;
            // position: absolute;
            // bottom: 5px;
            width: -webkit-fill-available;
            border-top: 1px solid rgba(72, 61, 61, 0.1882352941);
            height: 40px;
            align-items: center;
            padding-top: 5px;

            .btn-edit {
                margin-left: 10px;
                color: var(--button-fg-color);
                background-color: var(--button-bg-color);
            }

            .btn-delete {
                margin: auto;
                margin-right: 10px;
                color: var(red);
                background-color: var(--button-bg-color);
            }
        }


    }

    .no-page-data {
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 15px;
        font-weight: 500;
        // background-color: white;
        span {
            text-wrap: nowrap;
        }

        img {
            height: 180px;
        }
    }

}