/* You can add global styles to this file, and also import other style files */
@import '~quill/dist/quill.snow.css';

:root {

    --selection-bg-color: lightgray;
    --highlight-bg-color: rgba(255, 232, 158, 0.342);

    --top-toolbar-height: 56px;
    --left-sidebar-width: 250px;
    --left-sidebar-min-width: 80px;
    --left-sidebar-minimized-width: 350px;

    --top-toolbar-bg-color: #eaf1fb8a;
    --top-toolbar-fg-color: #ffffff;

    --left-sidebar-bg-color: white; // #eaf1fb; // #ffffff;
    --left-sidebar-fg-color: #000000;

    --left-sidebar-menu-title-color: white;
    --left-sidebar-menu-hover-color: rgba(180, 178, 172, 0.342);
    --left-sidebar-menu-selected-color: #eaf1fb;

    --right-summary-panel-max-width: 320px;
    --right-summary-panel-min-width: 50px;
    --right-summary-panel-bg-color: white; //var(--top-toolbar-bg-color);

    --left-side-optionbar-bg-color: var(--top-toolbar-bg-color);
    --left-side-optionbar-width: 150px;

    //table variables
    --table-label-bg-green: #8fd3a1;
    --table-label-bg-red: #ff4a4a;
    --table-bgcolor: white;
    --table-forecolor: black;
    --table-row-height: 40px;
    --table-row-selection-bg-color: var(--selection-bg-color);
    --table-row-highlight-bg-color: var(--highlight-bg-color);

    --content-page-bg-color: rgba(211, 211, 211, 0.623);
    --content-page-section-bg-color: white;

    // Button colors

    --button-fg-color: black;
    --button-bg-color: #d3d3d354;
    --button-hover-color: #d3d3d37c;

    --button-add-fg-color: black; // rgb(61, 126, 179);
    --button-add-bg-color: #d3d3d354; // rgba(61, 126, 179, 0.08);

    --button-edit-fg-color: rgb(61, 93, 179);
    --button-edit-bg-color: #d3d3d354; // rgba(61, 93, 179, 0.08);

    --button-delete-fg-color: rgb(255, 0, 0);
    --button-delete-bg-color: #d3d3d354; // rgba(255, 0, 0, 0.08);

    --button-export-fg-color: rgb(80, 56, 25);
    --button-export-bg-color: #d3d3d354; // rgba(80, 56, 25, 0.08);

}


html,
body {
    height: 100%;
    user-select: none;
    font-family: 'Roboto', sans-serif;
}

body {
    margin: 0;
    // font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-drawer-side {
    // box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border-color: #ffffff00;
}

mat-paginator.mat-mdc-paginator.paginator {
    background: none !important;

    .mat-mdc-paginator-container {
        min-height: unset !important;
        height: 100% !important;
    }

    .mat-mdc-paginator-range-actions {
        button {
            width: 48px !important;
            height: 48px !important;
        }
    }

    .mat-mdc-paginator-range-label {
        margin: unset !important;
    }
}


div#mat-paginator-page-size-label-0 {
    width: 33px;
    text-wrap: nowrap;
    overflow: hidden;
}

.mat-mdc-paginator-range-label {
    margin: 2px;
}

@media (min-width:1025px) {
    ::-webkit-scrollbar {
        width: 5px;
        overflow-y: scroll;
        background: rgba(128, 128, 128, 0.021);
        box-shadow: inset 0 0 4px #707070;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(255, 0, 85, 0.356);
        border-radius: 10px;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // background-color: rgba(116, 28, 28, 0.473);
    z-index: 101;
}

.filter-container {
    .cust_page_display_style {
        // background-color: yellow !important;
        cursor: pointer;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        padding: 3px;

        &:hover {
            background-color: rgba(211, 211, 211, 0.568);

        }
    }
}

.customoer-component-container {
    .mat-column-amount {
        text-align: right !important;
        padding-right: 5px !important;
    }
}

.customoer-component-container {
    .mat-column-amount {
        text-align: right !important;
        padding-right: 5px !important;

        .mat-sort-header-container {
            justify-content: end !important;
            padding: 0px !important;
        }
    }
}

.mat-button-toggle {
    background-color: rgb(242, 245, 247);
}

.mat-button-toggle-checked {
    background-color: rgb(0, 102, 255);
    color: white;
}

.page-root-container {
    padding: 5px;
    // background-color: var(--content-page-bg-color)!important;
    border-radius: 20px;

    .page-title {
        font-weight: 400;
        height: 30px;
        display: flex;
        align-items: center;
        font-size: 18px;
        margin: 3px;
        flex: 1;
        text-transform: uppercase;

        .icon {
            width: 24px;
            margin-right: 15px;
        }

        .title {
           
        }
    }
}

.shadow{
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 8px !important;
}